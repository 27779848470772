import React from 'react';
import { graphql } from 'gatsby';
import { decodeObjectSpecialCharacters } from '../utils/utils';
import Hero from '../components/Hero';
import LayoutPage from '../components/LayoutPage';
import SEO from '../components/SEO';
import ContentModules from '../components/ContentModules';
import PageBreadcrumb from '../components/PageBreadcrumb';

const Page = (props) => {
  const { data } = props;
  const yoastMeta = decodeObjectSpecialCharacters(data.wordpressWpBrand.yoast_meta);

  const url = props.location?.href;
  const variants = data.allWordpressWpCar;
  const variantCount = variants.totalCount;
  const schemaImage = yoastMeta.yoast_wpseo_facebook_image || yoastMeta.yoast_wpseo_twitter_image;

  const title =
    data.wordpressWpBrand.page_meta_fields.title || data.wordpressWpBrand.title.rendered;

  const variantsItems = variants?.edges.map((variant, index) => {
    return {
      '@type': 'ListItem',
      position: index + 1,
      url: variant.node.post_meta_fields.variant_url,
      name: variant.node.post_meta_fields.title,
    };
  });

  const schemaMarkup = {
    '@context': 'https://schema.org/',
    '@type': 'ItemList',
    name: title,
    image: schemaImage,
    description: yoastMeta.yoast_wpseo_metadesc,
    url: url,
    numberOfItems: variantCount,
    itemListElement: variantsItems,
  };

  const crumbs = [
    {
      pathname: '/',
      crumbLabel: 'Home',
    },
    {
      pathname: `/${data.wordpressWpBrand.slug}`,
      crumbLabel: data.wordpressWpBrand.name,
    },
  ];

  return (
    <main>
      <SEO
        description={yoastMeta.yoast_wpseo_metadesc}
        ogDesc={yoastMeta.yoast_wpseo_facebook_description}
        ogImg={yoastMeta.yoast_wpseo_facebook_image}
        ogTitle={yoastMeta.yoast_wpseo_facebook_title}
        title={yoastMeta.yoast_wpseo_title}
        twitterDesc={yoastMeta.yoast_wpseo_twitter_description}
        twitterImg={yoastMeta.yoast_wpseo_twitter_image}
        twitterTitle={yoastMeta.yoast_wpseo_twitter_title}
        structuredData={schemaMarkup}
      />

      <LayoutPage>
        <Hero
          buttons={data.wordpressWpBrand.acf.buttons}
          content={data.wordpressWpBrand.acf.content}
          headline={
            data.wordpressWpBrand.page_meta_fields.title || data.wordpressWpBrand.title.rendered
          }
          slides={data.wordpressWpBrand.acf.slides}
          subtitle={data.wordpressWpBrand.acf.pre_title}
          isSearchButton={props.pageContext.isHome}
        />

        <PageBreadcrumb crumbs={crumbs} location={props.location} />

        {data.wordpressWpBrand &&
          data.wordpressWpBrand.acf &&
          data.wordpressWpBrand.acf.content_modules_undefined &&
          data.wordpressWpBrand.acf.content_modules_undefined.map((module, index) => (
            <ContentModules key={`content-module-page-${index}`} index={index} module={module} />
          ))}
      </LayoutPage>
    </main>
  );
};

export const query = graphql`
  query BrandsQuery($id: Int) {
    allWordpressWpCar(filter: { brand: { eq: $id } }) {
      totalCount
      edges {
        node {
          post_meta_fields {
            variant_url
            title
          }
          slug
        }
      }
    }

    wordpressWpBrand(wordpress_id: { eq: $id }) {
      __typename
      wordpress_id
      name
      slug
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
      acf {
        buttons {
          button {
            title
            url
            target
          }
        }
        content
        pre_title
        slides {
          highlight {
            post_meta_fields {
              capid
              brand_title
              title
              variant_url
              __typename
            }
          }
          image {
            alt_text
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 80
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                  height: 856
                  width: 1600
                  transformOptions: {
                    cropFocus: CENTER
                    duotone: { highlight: "#000000", shadow: "#000000", opacity: 50 }
                  }
                  layout: CONSTRAINED
                )
              }
            }
          }
        }
        content_modules_undefined {
          ... on WordPressAcf_multi_image_blocks {
            __typename
            title
            pre_title
            mib_rows {
              mib_columns {
                mib_title
                mib_link {
                  title
                  url
                  target
                }
                mib_image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        quality: 80
                        placeholder: BLURRED
                        formats: [AUTO, WEBP, AVIF]
                        width: 497
                        layout: CONSTRAINED
                      )
                    }
                  }
                }
              }
            }
          }
          ... on WordPressAcf_intro_features {
            __typename
            title
            description
            intro
          }
          ... on WordPressAcf_intro_steps {
            __typename
            id
            content {
              blockContent: content
              steps {
                content
                title
              }
            }
            overview {
              buttons {
                button {
                  title
                  target
                  url
                }
                type
              }
              pre_title
              title
            }
          }
          ... on WordPressAcf_magazine_blocks {
            __typename
            rows {
              cols {
                link {
                  url
                  title
                  target
                }
                contents
                subtext_colour
                subtitle
                text_align
                title
                title_colour
                image {
                  alt_text
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        quality: 80
                        placeholder: BLURRED
                        formats: [AUTO, WEBP, AVIF]
                        width: 800
                        layout: CONSTRAINED
                      )
                    }
                  }
                }
              }
            }
          }
          ... on WordPressAcf_special_offers {
            __typename
            id
            title
            pre_title
            tabs {
              title
              derivatives {
                guid
                post_meta_fields {
                  capid
                  price_24m {
                    mileage_8k
                    mileage_9k
                    mileage_10k
                    mileage_11k
                    mileage_12k
                    mileage_13k
                    mileage_14k
                    mileage_15k
                    mileage_16k
                    mileage_17k
                    mileage_18k
                    mileage_19k
                    mileage_20k
                  }
                  price_36m {
                    mileage_8k
                    mileage_9k
                    mileage_10k
                    mileage_11k
                    mileage_12k
                    mileage_13k
                    mileage_14k
                    mileage_15k
                    mileage_16k
                    mileage_17k
                    mileage_18k
                    mileage_19k
                    mileage_20k
                  }
                  price_48m {
                    mileage_8k
                    mileage_9k
                    mileage_10k
                    mileage_11k
                    mileage_12k
                    mileage_13k
                    mileage_14k
                    mileage_15k
                    mileage_16k
                    mileage_17k
                    mileage_18k
                    mileage_19k
                    mileage_20k
                  }
                  specs {
                    spec_BHP
                    spec_0_62
                    spec_MPG3
                    spec_CO2
                  }
                  special_offer
                  special_offer_info_bar
                  brand_title
                  title
                  variant_url
                }
              }
            }
          }
          ... on WordPressAcf_image_with_content {
            __typename
            id
            colour_mode
            image_align
            title
            blockContent: content
            image {
              alt_text
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 80
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                    height: 673
                    layout: CONSTRAINED
                  )
                }
              }
            }
          }
          ... on WordPressAcf_accordion_faq {
            __typename
            id
            colour_mode
            title
            pre_title
            add_contact_button
            items {
              content_answer
              title_question
            }
          }
          ... on WordPressAcf_brand_blocks {
            __typename
            pre_title
            title
            rows {
              cols {
                link {
                  url
                  title
                  target
                }
                contents
                subtitle
                title
                description
                image {
                  alt_text
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        quality: 80
                        placeholder: BLURRED
                        formats: [AUTO, WEBP, AVIF]
                        layout: FULL_WIDTH
                      )
                    }
                  }
                }
              }
            }
            id
          }
          ... on WordPressAcf_reviews {
            __typename
            id
            reviews {
              post_title
              post_content
              acf {
                source
                rating
              }
            }
            pre_title
            title
            is_dark
          }
          ... on WordPressAcf_call_to_action {
            __typename
            buttons {
              type
              button {
                title
                target
                url
              }
            }
            pre_title
            title
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 80
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                    transformOptions: {
                      duotone: { highlight: "#000000", shadow: "#000000", opacity: 50 }
                    }
                    layout: FULL_WIDTH
                  )
                }
              }
            }
          }
          ... on WordPressAcf_image_with_content_multiple {
            __typename
            id
            title
            submodules {
              colour_mode
              content
              image_align
              image {
                alt_text
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      quality: 80
                      height: 673
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                      layout: CONSTRAINED
                    )
                  }
                }
              }
            }
          }
          ... on WordPressAcf_intro {
            __typename
            id
            pre_title
            title
            intro
          }
          ... on WordPressAcf_general_content {
            __typename
            id
            main_content
            supporting_content {
              content
            }
          }
          ... on WordPressAcf_accordion_faq_review {
            __typename
            id
            items {
              content_answer
              title_question
            }
            title
            pre_title
            add_contact_button
            featured_review {
              post_content
              post_title
              acf {
                rating
              }
            }
          }
          ... on WordPressAcf_slider {
            __typename
            id
            title
            pre_title
            slides {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 80
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                    transformOptions: {
                      duotone: { highlight: "#000000", shadow: "#000000", opacity: 50 }
                    }
                    layout: FULL_WIDTH
                  )
                }
              }
            }
          }
          ... on WordPressAcf_video {
            __typename
            id
            youtube_video_id
          }
          ... on WordPressAcf_large_links {
            __typename
            id
            blocks {
              pre_title
              title
              type
              overlay
              link {
                title
                url
                target
              }
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      quality: 80
                      height: 800
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                      transformOptions: {
                        duotone: { highlight: "#000000", shadow: "#000000", opacity: 50 }
                      }
                      layout: CONSTRAINED
                    )
                  }
                }
              }
            }
          }
        }
      }
      page_meta_fields {
        title
      }
    }
  }
`;

export default Page;
